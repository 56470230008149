import { Breadcrumb } from 'antd';
import dynamic from 'next/dynamic';

import { FormattedMessage } from 'react-intl';
import Layout from '~/v2/components/layout/Layout';
import LoginForm from '~/v2/parts/auth/LoginForm';

const Container = dynamic(() => import('../components/other/Container'));

const Login = () => {
  return (
    <Layout
      title='Login'
      head={
        <>
          <link rel='canonical' href='https://coffeepii.com/login' />
        </>
      }
    >
      <Container>
        <Breadcrumb separator='>'>
          <Breadcrumb.Item>
            <i className='fas fa-home' />
            <FormattedMessage id='app.common.home' defaultMessage='Home' />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <FormattedMessage id='app.common.login' defaultMessage='Login' />
          </Breadcrumb.Item>
        </Breadcrumb>

        <LoginForm />
      </Container>
    </Layout>
  );
};

export default Login;
